<template>
  <EventsStyled>
    <PanelTableMolecule
      :isLoading="loading"
      :count="totalCount"
      :columns="columns"
      :rows="tableData"
      :pageIndex="page + 1"
      :pageSize="perPage"
      @pageNumberChange="pageNumberChange($event)"
      @reload="reload"
    >
      <template v-slot:title>
        {{ $tc('event', 2) | capitalize }}
      </template>
    </PanelTableMolecule>
    <router-view />
  </EventsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { getRolesFromToken } from '@common/utils'
import { Edit2Icon } from 'vue-feather-icons'
import { DateTime } from 'luxon'
import { get } from 'lodash'

import EVENT_QUERYS from '#/graphql/events/query/listEvents.graphql'
import EVENT_COUNT_QUERY from '#/graphql/events/query/eventCount.graphql'

import PanelTableMolecule from '@/components/Atomic/Molecules/PanelTableMolecule'
import EntityLinkAtom from '@/components/Atomic/Atoms/EntityLinkAtom'

const EventsStyled = styled('div')`
  margin: 1rem;
  .actions {
    display: flex;
    flex-direction: row;
    padding: 5px;
    justify-content: space-evenly;

    color: ${p => p.theme.colors.primary};

    > div,
    button {
      padding: 0;
      cursor: pointer;
      > svg {
        color: ${p => p.theme.colors.primary};
      }
      &:hover {
        color: ${props => props.theme.colors.white};
      }
    }
  }
`

export default {
  inject: ['uiSettings'],
  components: {
    EventsStyled,
    PanelTableMolecule,
  },
  data() {
    return {
      loading: true,
      count: 0,
      totalCount: 0,
      events: [],
      searchQuery: '',
      page: 0,
      perPage: 10,
      where: {},
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalCount / this.perPage)
    },
    hasNextPage() {
      return this.page < this.totalPages - 1
    },
    hasPreviousPage() {
      return this.page > 0
    },
    skip() {
      return this.page * this.perPage
    },
    userRoles() {
      return getRolesFromToken(this.$keycloak.token)
    },
    hasWritePermission() {
      return this.userRoles.includes('archon') || this.userRoles.includes('talpa-product')
    },
    editRoute() {
      if (this.hasWritePermission) {
        return 'event'
      } else {
        return null
      }
    },
    columns() {
      const header = [
        {
          field: 'id',
          key: 'id',
          title: 'Id',
          align: 'left',
          renderBodyCell: ({ row }) => {
            const { id, type } = row
            if (!id || !type) {
              return `something missing`
            }
            return <EntityLinkAtom type={type} id={id} />
          },
        },
        { field: 'eventType', key: 'eventType', title: 'Type', align: 'center' },
        { field: 'recipientType', key: 'recipientType', title: 'Recipient Type', align: 'center' },
        {
          field: 'recipient',
          key: 'recipient',
          title: 'Recipient',
          align: 'center',
        },
        { field: 'created', key: 'created', title: 'Created', align: 'center' },
        {
          field: 'actions',
          key: 'actions',
          title: 'Actions',
          width: '',
          center: 'left',
          renderBodyCell: ({ row }) => {
            return (
              <div class="actions">
                <button
                  on-click={() => this.$router.push({ name: this.editRoute, params: { id: row?.rowKey } })}
                  disabled={!this.hasWritePermission}
                >
                  <Edit2Icon size="1.3x" />
                </button>
              </div>
            )
          },
        },
      ]
      return header
    },
    tableData() {
      const tableData = this.events.map(event => {
        return {
          id: event.id,
          eventType: event.type,
          recipientType: event.recipient?.__typename,
          recipient: event.recipient?.name,
          recipientId: event.recipient?.id,
          created: this.formatDateTime(event.createdAt),
          rowKey: event.id,
          type: event.__typename,
        }
      })
      return tableData
    },
    locale() {
      const ds = get(this.uiSettings, 'dates', 'DE_DE')
      return ds.replace('_', '-').toLowerCase()
    },
  },
  methods: {
    routerLinkFromRecipient(recipient) {
      return {
        name: 'subsidiary',
        params: {
          id: recipient.id,
        },
      }
    },
    reload() {
      this.searchQuery = ''
      this.$apollo.queries.events.refetch()
      this.$apollo.queries.totalCount.refetch()
    },
    pageNumberChange(pageIndex) {
      this.page = pageIndex - 1
    },
    searchCancel(closeFn) {
      this.searchQuery = ''
      closeFn()
    },
    searchConfirm(closeFn) {
      closeFn()
    },
    formatDateTime(date) {
      const localTime = DateTime.fromISO(date).setLocale(this.locale)
      return `${localTime.toLocaleString(DateTime.DATE_SHORT)} - ${localTime.toLocaleString(DateTime.TIME_SIMPLE)}`
    },
  },
  watch: {
    searchQuery: {
      handler(s) {
        this.page = 0
        if (s) {
          this.where = {
            name_contains: s,
          }
        } else {
          this.where = undefined
        }
      },
    },
  },
  apollo: {
    events: {
      query: EVENT_QUERYS,
      variables() {
        return {
          take: this.perPage,
          skip: this.skip,
          where: this.where,
        }
      },
      manual: true,
      result({ data, loading }) {
        this.loading = loading
        this.events = data?.events
      },
    },
    totalCount: {
      query: EVENT_COUNT_QUERY,
      variables() {
        return {
          where: this.where,
        }
      },
      manual: true,
      result({ data }) {
        this.totalCount = data?.eventCount
      },
    },
  },
}
</script>
