var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('EventsStyled', [_c('PanelTableMolecule', {
    attrs: {
      "isLoading": _vm.loading,
      "count": _vm.totalCount,
      "columns": _vm.columns,
      "rows": _vm.tableData,
      "pageIndex": _vm.page + 1,
      "pageSize": _vm.perPage
    },
    on: {
      "pageNumberChange": function pageNumberChange($event) {
        return _vm.pageNumberChange($event);
      },
      "reload": _vm.reload
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm._f("capitalize")(_vm.$tc('event', 2))) + " ")];
      },
      proxy: true
    }])
  }), _c('router-view')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }